<template>
  <div>
    <hr class="yellow-rule" />
    <v-container v-if="salary.company">
      <v-row>
        <v-breadcrumbs :items="breadCrumbs" large />
      </v-row>
      <!-- Show page heading -->
      <v-row>
        <v-col cols="12">
          <h1 class="mb-2">
            {{
              $t("page_header", {
                company: salary.company.name,
                job_role: salary.job_role
              })
            }}
            <span v-if="salary.years_at_company"
              >({{
                $tc("page_header_year", salary.years_at_company, {
                  years_at_company: salary.years_at_company
                })
              }})</span
            >
          </h1>
          <underline />
        </v-col>
      </v-row>
      <!-- Three information section -->
      <v-row class="flex-column flex-md-row">
        <!-- Compensation Summary -->
        <v-col cols="12" md="4">
          <v-container>
            <v-row class="text-h5 font-weight-bold">
              <v-col cols="5">{{ $t("annual_compensation") }}</v-col>
              <v-col cols="7" class="text-right success--text">{{
                displayCompensationItem(salary.annual_compensation)
              }}</v-col>
            </v-row>
            <v-row class="text-h6">
              <v-col cols="5">{{ $t("annual_base_salary") }}</v-col>
              <v-col cols="7" class="text-right">{{
                displayCompensationItem(salary.annual_salary)
              }}</v-col>
            </v-row>
            <v-row class="text-h6">
              <v-col cols="5">{{ $t("annual_bonus") }}</v-col>
              <v-col cols="7" class="text-right">{{
                displayCompensationItem(salary.annual_bonus)
              }}</v-col>
            </v-row>
            <v-row class="text-h6">
              <v-col cols="5">{{ $t("annual_stock") }}</v-col>
              <v-col cols="7" class="text-right">{{
                displayCompensationItem(salary.annual_stock)
              }}</v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp" />
        <!-- Job / Role summary -->
        <v-col cols="12" md="4">
          <v-container>
            <v-row class="text-h6 font-weight-bold">
              <v-col cols="12">{{ $t("job_information") }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("grade") }}</v-col>
              <v-col cols="8">{{ salary.grade }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("job_role") }}</v-col>
              <v-col cols="8">{{ salary.job_role }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("role_title") }}</v-col>
              <v-col cols="8">{{ salary.role_title || "-" }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("role_focus") }}</v-col>
              <v-col cols="8">{{ salary.role_focus }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("years_at_company") }}</v-col>
              <v-col cols="8">{{ salary.years_at_company || "-" }}</v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp" />
        <!-- Misc information -->
        <v-col cols="12" md="4">
          <v-container>
            <v-row class="text-h6 font-weight-bold">
              <v-col cols="12">{{ $t("additional_information") }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("gender") }}</v-col>
              <v-col cols="8">{{ $t(salary.gender) }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("education_level") }}</v-col>
              <v-col cols="8">{{ salary.education_level }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("age") }}</v-col>
              <v-col cols="8">{{ salary.age }}</v-col>
            </v-row>
            <v-row class="text-body-2">
              <v-col cols="4">{{ $t("years_of_experience") }}</v-col>
              <v-col cols="8">{{ salary.years_of_experience }}</v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-divider v-if="$vuetify.breakpoint.xsOnly" />
      <!-- Share link and report problem -->
      <v-row class="justify-end gray_darker--text mt-6">
        <v-tooltip
          v-model="show"
          bottom
          nudge-top="15"
          content-class="text-caption"
        >
          <template v-slot:activator="{ attrs }">
            <v-col
              v-bind="attrs"
              cols="auto"
              class="gray_darker--text cursor-pointer"
              @click="copyLinkAndShowTooltip"
            >
              <v-icon small class="mr-1 mb-1"> mdi-link </v-icon
              >{{ $t("copy_link") }}
            </v-col>
          </template>
          <span class="text-caption">{{ $t("copied") }}</span>
        </v-tooltip>
        <v-col cols="auto">
          <v-icon small class="mr-1 mb-1">
            mdi-alert-octagon
          </v-icon>
          <a
            :href="reportItemLink(salary)"
            target="_blank"
            class="gray_darker--text"
          >
            {{ $t("report_item") }}
          </a>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-12 mb-md-0 mb-12">
        <v-btn
          color="secondary pa-6 text-caption text-md-button"
          rounded
          :to="
            $i18nRoute({
              name: 'showCompany',
              params: {
                company_slug: this.salary.company.slug,
                role_slug: 'software-engineer'
              }
            })
          "
          max-width="100%"
        >
          {{ $t("jump_to_company_show", { company: salary.company.name }) }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import store from "store";
import api from "services/api";
import Underline from "components/Underline.vue";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  components: {
    Underline
  },
  created() {
    this.getSalary(this.$route.params.id);
  },
  data: () => ({
    show: false,
    salary: {}
  }),
  computed: {
    locale() {
      return store.getters.locale;
    },
    breadCrumbs() {
      return [
        {
          text: this.$t("salaries"),
          href: this.locale.base + "/"
        },
        {
          text: this.$t("company_listing"),
          href: this.locale.base + "/companies"
        },
        {
          text: this.salary?.company?.name,
          href: this.locale.base + "/companies/" + this.salary?.company?.slug
        },
        {
          text: this.$t("bread_crumbs_title", {
            job_role: this.salary?.job_role
          }),
          disabled: true
        }
      ];
    }
  },
  methods: {
    async getSalary(id) {
      try {
        const resp = await api.getSalary(id);
        if (resp.status !== 200) return;
        this.salary = resp.data;
      } catch (error) {
        this.$router.push(this.$i18nRoute({ name: "home" })); // should be 404?
      }
    },
    copyLinkAndShowTooltip() {
      navigator.clipboard.writeText(window.location.href);
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    reportItemLink(item) {
      const subject = encodeURIComponent(
        `${this.$t("report_issue")} (id: ${item.id})`
      );
      const body = encodeURIComponent(
        `Id: ${item.id}\n` +
          `${this.$t("company_name")}: ${this.salary.company.name}\n` +
          `${this.$t("role")}: ${item.role_title}\n` +
          `${this.$t("role_focus")}: ${item.role_focus}\n` +
          `${this.$t("grade")}: ${item.grade}\n` +
          `${this.$t("annual_compensation")}: ${this.displayCompensationItem(
            item.annual_compensation
          )}\n\n` +
          `${this.$t("describe_problem")}`
      );

      return `mailto:hello@opensalary.jp?subject=${subject}&body=${body}`;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "salaries": "Home",
    "company_listing": "Companies",
    "bread_crumbs_title": "{job_role} compensation",
    "page_header": "{job_role} compensation at {company}",
    "page_header_year": "{years_at_company} year | {years_at_company} years",
    "additional_information": "Additional Information",
    "job_information": "Job Information",
    "gender": "Gender",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "unknown": "Unknown",
    "education_level": "Education Level",
    "age": "Age",
    "annual_compensation": "Total Compensation",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "role": "Role",
    "grade": "Grade/Level",
    "role_focus": "Focus",
    "role_title": "Official Job Title",
    "job_role": "Job Category",
    "years_at_company": "Years at Company",
    "years_of_experience": "Years of Experience",
    "report_item": "Report issue with salary entry",
    "jump_to_company_show": "More {company} salary information",
    "copy_link": "Copy URL",
    "copied": "Copied!",
    "describe_problem": "Describe what is wrong with this entry:",
    "company_name": "Company",
    "report_issue": "Report issue with salary entry"
  },
  "ja": {
    "salaries": "ホーム",
    "company_listing": "企業一覧",
    "bread_crumbs_title": "{job_role}の年収",
    "page_header": "{company}の{job_role}年収",
    "page_header_year": "{years_at_company}年目",
    "additional_information": "補足情報",
    "job_information": "職種情報",
    "gender": "性別",
    "male": "男性",
    "female": "女性",
    "other": "その他",
    "unknown": "不明",
    "education_level": "最終学歴",
    "age": "年齢",
    "annual_compensation": "年収 (総額)",
    "annual_base_salary": "基本給 (年)",
    "annual_bonus": "賞与 (年)",
    "annual_stock": "株式報酬 (年)",
    "role": "職種",
    "grade": "グレード・レベル",
    "role_focus": "フォーカス",
    "role_title": "企業内での職種名",
    "job_role": "職務領域",
    "years_at_company": "勤続年数",
    "years_of_experience": "経験年数",
    "report_item": "問題を報告する",
    "jump_to_company_show": "{company}の年収情報をもっと見る",
    "copy_link": "このページのリンクをコピーする",
    "copied": "リンクをコピーしました！",
    "describe_problem": "通報内容をご記入ください：",
    "company_name": "企業名",
    "report_issue": "年収情報の誤りを報告する"
  }
}
</i18n>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
