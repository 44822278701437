import store from "../store";

export const displayCompensationItemMixin = {
  computed: {
    code() {
      return store.getters.locale.code;
    }
  },
  methods: {
    displayCompensationItem(value) {
      if (value === null) {
        return "-";
      }

      const options = {
        notation: "compact",
        currency: "JPY",
        style: "currency",
        maximumFractionDigits: this.code === "ja" ? 0 : 1, // for japanese don't allow 150.5万円, but allow ¥1.5M for english
        currencyDisplay: this.code === "ja" ? "name" : "symbol" // japanese: ~円, english: ¥~
      };

      const formatter = Intl.NumberFormat(this.code, options);
      return formatter.format(value);
    }
  }
};
